import React, { useEffect, useState } from "react";

import { Container, Header, Button, InputText } from "../components";

import { HOST } from "../utils";

const Title = ({ locale }) => {
  switch (locale) {
    case 'fr':
      return (<h1>French questions</h1>)
  
    case 'es':
      return (<h1>Spanish questions</h1>)

    case 'nl':
      return (<h1>Dutch questions</h1>)

    case 'de':
      return (<h1>German questions</h1>)
  
    default:
      break;
  }
}

const AdmForm = ({ locale }) => {
  const [questions, setQuestions] = useState([]);
  const [viewCreate, setViewCreate] = useState(false);

  const getQuestions = async (locale) => {
    let res = await fetch(HOST + "/questions/" + locale);
    res = await res.json();
    setQuestions(res);
    setViewCreate(false);
  };

  const deleteQuestion = async (id) => {
    let res = await fetch(HOST + "/questions/" + id, { method: "DELETE" });
    res = await res.json();
    if (res.status === "ok") {
      getQuestions(locale);
    }
  };

  const modifyQuestion = async (item) => {
    let res = await fetch(HOST + "/questions/" + item._id, {
      method: "PUT",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(item),
    });

    res = await res.json();
    if (res.status === "ok") {
      getQuestions(locale);
    }
  };

  const createQuestion = async (item) => {
    const finalItem = { ...item, locale }

    let res = await fetch(HOST + "/questions", {
      method: "POST",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(finalItem),
    });

    res = await res.json();
    if (res.status === "ok") {
      getQuestions(locale);
    }
  };

  useEffect(() => {
    const getQuestions = async (locale) => {
      let res = await fetch(HOST + "/questions/" + locale);
      res = await res.json();
      setQuestions(res);
    };
    getQuestions(locale);
  }, []);

  if (viewCreate) {
    return (
      <Container>
        <Title locale={locale} />
        <Header>
          <Button onClick={() => setViewCreate(false)}>Cancel</Button>
        </Header>
        <InputText onCreate={createQuestion} />
      </Container>
    );
  }

  return (
    <Container>
      <Title locale={locale} />
      <Header>
        <Button onClick={() => setViewCreate(true)}>Add question</Button>
      </Header>
      <div>
        {questions.map((item) => (
          <div key={item._id}>
            <InputText
              item={item}
              onDelete={deleteQuestion}
              onModify={modifyQuestion}
            />
            <hr style={{ marginTop: 20, marginBottom: 20 }} />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default AdmForm;
