import { useState } from "react";
import {
  Container,
  Title,
  Grid,
  MobileMenu,
  DesktopMenu,
  NavWrapper,
  Link,
  ImgLink,
  ScreenMode,
} from "./styled-layout";

import desktop_menu from "../../assets/desktop-menu.png";
import mobile_menu from "../../assets/mobile-menu.png";

const Layout = ({ children, title, img, textImg }) => {
  const [open, setOpen] = useState(false);
  const TextImg = textImg;
  return (
    <Grid>
      <MobileMenu>
        <img
          onClick={() => setOpen(!open)}
          className="icon-menu"
          src={mobile_menu}
          alt="Menú"
        />
        <Title img={img} mode="mobile">
          <TextImg />
        </Title>
      </MobileMenu>
      <NavList open={open} onClick={() => setOpen(!open)} />
      <ScreenMode>
        <Title img={img} modo="desktop">
          <TextImg />
        </Title>
      </ScreenMode>
      <Container>{children}</Container>
      <DesktopMenu>
        <ImgLink to="/">
          <img className="desktop-img" src={desktop_menu} alt="Menú" />
        </ImgLink>
      </DesktopMenu>
    </Grid>
  );
};

const NavList = ({ onClick, ...props }) => {
  return (
    <NavWrapper {...props}>
      <Link to="/" onClick={onClick}>
        Home
      </Link>
      <br />
      <Link to="/questionnaire" onClick={onClick}>
        Questionnaire
      </Link>
      <br />
      <Link to="/about" onClick={onClick}>
        About
      </Link>
      <br />
      <Link to="/contagious" onClick={onClick}>
        Contagious Speech
      </Link>
      <br />
      <Link to="/live-stream" onClick={onClick}>
        Live Stream
      </Link>
      <br />
      <Link to="/zoom-performance" onClick={onClick}>
        Zoom Performance
      </Link>
    </NavWrapper>
  );
};

export default Layout;
