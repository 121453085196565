import { Bubble } from "../components";
import { Container } from "../styles";

import {
  questionnaireImgs,
  aboutImgs,
  contagiousImgs,
  livesImgs,
  zoomImgs,
} from "../utils";

function Home() {
  return (
    <Container>
      <Bubble
        src={questionnaireImgs}
        text="Questionnaire"
        to="/questionnaire"
        delay={2.5}
        top="10%"
        left="5%"
        alignSelf="end"
        className="bobo"
      />
      <div className="bobo" />
      <Bubble
        delay={2}
        src={aboutImgs}
        text="about"
        to="/about"
        alignSelf="end"
        right="10%"
        top="30%"
      />
      <div className="bobo" />
      <Bubble
        src={questionnaireImgs}
        text="Questionnaire"
        to="/questionnaire"
        delay={2.5}
        top="10%"
        left="5%"
        alignSelf="start"
        className="reverse-bobo"
      />
      <Bubble
        delay={1}
        src={contagiousImgs}
        text="Contagious Speech"
        to="/contagious"
        alignSelf="end"
        left="0%"
      />
      <div className="bobo" />
      <Bubble
        delay={3}
        src={livesImgs}
        text="Live Stream"
        to="/live-stream"
        alignSelf="start"
        left="10%"
        // width="90%"
        bottom="20%"
      />
      <div className="bobo" />
      <Bubble
        delay={1.5}
        src={zoomImgs}
        text="Zoom Performance"
        to="/zoom-performance"
        alignSelf="end"
        bottom="20%"
      />
    </Container>
  );
}

export default Home;
