import styled from "styled-components";
import { Link as rLink } from "react-router-dom";

import gradiant from "../../assets/gradiant.png";

export const Grid = styled.div`
  display: block;
  padding-bottom: 20px;
  max-width: 90vw;
  box-sizing: border-box;
  @media only screen and (min-width: 840px) {
    display: grid;
    grid-template-columns: 1fr 1.3fr 1fr;
    min-height: 95vh;
    max-width: 100vw;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 70%;
  font-size: 16px;
  color: #6699cc;
  width: 100%;

  figure {
    width: 100%;
    margin: 0 auto;
    margin: 20px auto;
    iframe {
      width: 100%;
      max-height: 210px;
    }
    figcaption {
      font-size: 12px;
      color: #6699cc;
      text-align: center;
    }
  }

  a {
    color: #99cccc;
    :visited {
      color: #99cccc;
    }
  }

  hr {
    border: 0.5px solid #6699cc;
    margin: 15px 0;
  }

  @media only screen and (min-width: 840px) {
    margin-top: 20%;
    line-height: 28px;
    font-size: 18px;
    padding: 0;
    figure {
      figcaption {
        line-height: 12px;
        font-size: auto;
      }
      iframe {
        width: 100%;
        max-height: 260px;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    figure {
      figcaption {
        line-height: 12px;
        font-size: auto;
      }
      iframe {
        width: 100%;
        max-height: 400px;
      }
    }
  }
`;

Container.displayName = "Container";

export const Title = styled.div`
  background-image: url(${({ img }) => img});
  background-size: cover;
  width: 75%;
  svg {
    fill: #6699cc;
  }

  @media only screen and (min-width: 840px) {
    margin: -7% auto auto auto;
    /* margin: 0 auto; */
    img {
      width: 100%;
    }
  }
`;

export const ScreenMode = styled.div`
  display: none;
  @media only screen and (min-width: 840px) {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  background-image: url(${gradiant});
  background-position: center;
  background-size: cover;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  z-index: 1000;

  img.icon-menu {
    margin: 0;
    width: 60px;
    align-self: end;
    margin-right: 20px;
  }

  @media only screen and (min-width: 840px) {
    display: none;
  }
`;

export const DesktopMenu = styled.div`
  display: none;
  @media only screen and (min-width: 840px) {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
  }
`;

export const ImgLink = styled(rLink)`
  width: 25%;
  img {
    width: 100%;
  }
`;

export const NavWrapper = styled.nav`
  background-color: #2a43f4;
  position: fixed;
  top: 8.5vh;
  right: ${(props) => (props.open ? "0" : "-110%")};
  width: 100%;
  height: 92vh;
  transition: right 0.3s linear;
  padding-right: 10%;
  text-align: right;
  z-index: 1000;
  @media only screen and (min-width: 840px) {
    width: calc(100% / 3 - 20px);
    background-color: transparent;
  }
`;

export const Link = styled(rLink)`
  font-family: "Fugue Regular";
  font-size: 30px;
  margin-bottom: 40px;
  color: beige;
  text-decoration: none;
  :hover {
    color: aquamarine;
    cursor: pointer;
  }

  @media only screen and (min-width: 840px) {
    font-size: 26px;
    margin-bottom: none;
  }
`;
