import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ImgBubble from "./ImgBubble";

import {
  WrapperImg,
  BottomImg,
  MiddleImg,
  TopImg,
  TextImg,
} from "./styled-bubble";

const Bubble = ({ delay, src, to, text, history, ...props }) => {
  const navigate = useNavigate();

  const elementRefText = useRef(null);
  const elementRefTop = useRef(null);
  const elementRefMiddle = useRef(null);
  const elementRefBottom = useRef(null);

  const initialDelay = 1000 * delay;
  const intervalDelay = initialDelay + 7500;

  const topDelay = 5 + delay + "s";
  const middleDelay = 3 + delay + "s";
  const bottomDelay = 1 + delay + "s";

  useEffect(() => {
    const time = setTimeout(() => {
      elementRefTop.current.classList.toggle("visible");
      elementRefMiddle.current.classList.toggle("visible");
      elementRefBottom.current.classList.toggle("visible");
    }, initialDelay);

    return () => clearTimeout(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const time = setInterval(() => {
      elementRefTop.current.classList.toggle("visible");
      elementRefMiddle.current.classList.toggle("visible");
      elementRefBottom.current.classList.toggle("visible");
    }, intervalDelay);

    return () => clearTimeout(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const time = setInterval(() => {
      elementRefText.current.classList.toggle("animation");
    }, intervalDelay);

    return () => clearTimeout(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperImg {...props}>
      <ImgBubble
        elementRef={elementRefText}
        component={TextImg}
        src={src[3]}
        onClick={() => navigate(to)}
        delay={delay}
      />
      <ImgBubble
        elementRef={elementRefTop}
        component={TopImg}
        src={src[2]}
        delay={topDelay}
      />
      <ImgBubble
        elementRef={elementRefMiddle}
        component={MiddleImg}
        src={src[1]}
        delay={middleDelay}
      />
      <ImgBubble
        elementRef={elementRefBottom}
        component={BottomImg}
        src={src[0]}
        delay={bottomDelay}
      />
    </WrapperImg>
  );
};

export default Bubble;
