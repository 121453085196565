import React from "react";

import { Button } from "../button/Button";

const IntroQuestions = ({ onClick }) => {
  return (
    <div>
      <h2 className="color-fr">Cuestionario</h2>
      <p>
        When you click “Start”, you will see 35 statements. Click “yes” if you
        agree with the statement and click “no” if you don't. When you click
        “send” on the last screen, you will see how your answers compare with
        other people's. Your answers will be saved and compared with the answers
        of previous and future users.
      </p>
      <p className="color-fr">
      Cuando hagas clic en 'comenzar', verás 35 declaraciones. Haz clic en "sí" si estás de acuerdo con la declaración y haz clic en "no" cuando no lo estés. Cuando hagas clic en "enviar" en la última pantalla, verás cómo tus respuestas se comparan con las de otras personas. Tus respuestas se guardarán y compararán con las respuestas de usuaries anteriores y futures.
      </p>
      <Button onClick={onClick}>Start | Comenzar</Button>
      <p>
      This questionnaire was first developed as a bilingual French/English work for the exhibition
Contagious Speech at the Gallerie UQO in Quebec, Canada. It was translated to Spanish, and adapted
for the context of the exhibition The Future we speak at the Clemente Center in New York City,
curated by Eugenia Delfini.
Your participation is strictly meant to serve your own curiosity and enjoyment. Your name will not be
asked, and your metadata will not be saved.
      </p>
      <p className="color-fr">
      Este cuestionario se desarrolló por primera vez como un trabajo bilingüe francés/inglés para la exposición Contagious Speech en la Gallerie UQO en Quebec, Canadá. Fue traducido al español y adaptado para el contexto de la exposición “The Future we speak” en el Clemente Center en la ciudad de Nueva York curada por Eugenia Delfini. Tu participación está estrictamente destinada a servir a tu propia curiosidad y disfrute. No se te preguntará tu nombre y tus metadatos no se guardarán.

      </p>
    </div>
  );
};

export default IntroQuestions;
