import React, { createContext, useReducer, useContext } from "react";

import { initialState, questionnaireReducer } from "./reducers";

export const Context = createContext();

export const QuestionnaireProvider = ({ children }) => {
  const [state, dispatch] = useReducer(questionnaireReducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const useQuestionnaireContext = () => useContext(Context);
