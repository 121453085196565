import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import {
  Layout,
  Spinner,
  QuestionFormEs,
  QuestionFormFr,
  QuestionFormNl,
  QuestionFormDe,
  ResponsesListEs,
  ResponsesListFr,
  ResponsesListNl,
  ResponsesListDe,
  IntroQuestionsEs,
  IntroQuestionsFr,
  IntroQuestionsNl,
  IntroQuestionsDe,
  Button,
} from "../components";
import questionnaire from "../assets/questionnaire/3-QS.svg";
import { ReactComponent as textquestionnaire } from "../assets/questionnaire/4-QS.svg";

import useQuestionnaire from "../hooks";

const Questionnaire = () => {
  const {
    loading,
    questions,
    responses,
    getQuestions,
    position,
    addResponses,
    increment,
    viewResults,
    sendResponses,
    begin,
  } = useQuestionnaire();

  const radioYes = useRef();
  const radioNo = useRef();

  const [responseItem, setResponseItem] = useState();
  const [locale, setLocale] = useState();

  const localisedQuestions = questions.filter((question) => {
    return question.locale === locale
  })

  const results = Array.isArray(viewResults) ? viewResults : []
  const localisedResults = results.filter((result) => {
    return result?._id?.locale === locale
  })

  const flag = position < localisedQuestions.length;

  const onChange = (e) => {
    if (e.target.name === "yes") {
      radioNo.current.checked = false;
    } else {
      radioYes.current.checked = false;
    }
    setResponseItem({
      questionId: localisedQuestions[position]._id,
      response: e.target.value === "true",
    });
  };

  const handleNext = (e) => {
    e.preventDefault();

    if (!responseItem && locale === 'es')
      return alert("Select an option\nElegir una opción");

    if (!responseItem && locale === 'fr')
      return alert("Select an option\nSélectionner une option");

    if (!responseItem && locale === 'nl')
      return alert("Select an option\nKies een optie");
  
    if (!responseItem && locale === 'de')
      return alert("Select an option\nWählen Sie eine Option");

    radioYes.current.checked = false;
    radioNo.current.checked = false;

    addResponses(responseItem);
    setResponseItem(null);
    increment();
  };

  const handleSubmit = async () => {
    sendResponses(responses);
  };

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBegin = (locale) => {
    begin(locale)
    setLocale(locale)
  }

  if (loading) return <Spinner />;

  if (viewResults === "intro") {
    return (
      <Layout title="About" img={questionnaire} textImg={textquestionnaire}>
        <Centered>
          <IntroQuestionsNl onClick={() => handleBegin("nl")} />
          <hr class="separator" />
          <IntroQuestionsDe onClick={() => handleBegin("de")} />
          <hr class="separator" />
          <IntroQuestionsEs onClick={() => handleBegin("es")} />
          <hr class="separator" />
          <IntroQuestionsFr onClick={() => handleBegin("fr")} />
        </Centered>
      </Layout>
    );
  }

  if (Array.isArray(viewResults)) {
    return (
      <Layout title="About" img={questionnaire} textImg={textquestionnaire}>
        <Centered>
          {locale === 'nl' && (
            <ResponsesListNl
              results={localisedResults}
              responses={responses}
            />)}
          {locale === 'es' && (
            <ResponsesListEs
              results={localisedResults}
              responses={responses}
            />)}
          {locale === 'fr' && (
            <ResponsesListFr
              results={localisedResults}
              responses={responses}
            />
          )}
          {locale === 'de' && (
            <ResponsesListDe
              results={localisedResults}
              responses={responses}
            />
          )}
        </Centered>
      </Layout>
    );
  }

  return (
    <Layout title="About" img={questionnaire} textImg={textquestionnaire}>
      <Centered>
        {!flag && (
          <div>
            <Button onClick={handleSubmit}>
              <label>Send answers</label>
              {locale === 'es' && (<><br />Enviar respuestas</>)}
              {locale === 'fr' && (<><br /> Envoyer des résponses</>)}
              {locale === 'nl' && (<><br /> Antwoorden sturen</>)}
              {locale === 'de' && (<><br /> Antworten Senden</>)}
            </Button>
          </div>
        )}
        {flag && locale === 'es' && (
          <QuestionFormEs
            item={localisedQuestions[position]}
            onChange={onChange}
            refYes={radioYes}
            refNo={radioNo}
            handleNext={handleNext}
          />
        )
        }
        {flag && locale === 'fr' && (
          <QuestionFormFr
            item={localisedQuestions[position]}
            onChange={onChange}
            refYes={radioYes}
            refNo={radioNo}
            handleNext={handleNext}
          />
        )
        }
        {flag && locale === 'nl' && (
          <QuestionFormNl
            item={localisedQuestions[position]}
            onChange={onChange}
            refYes={radioYes}
            refNo={radioNo}
            handleNext={handleNext}
          />
        )
        }
        {flag && locale === 'de' && (
          <QuestionFormDe
            item={localisedQuestions[position]}
            onChange={onChange}
            refYes={radioYes}
            refNo={radioNo}
            handleNext={handleNext}
          />
        )
        }
      </Centered>
    </Layout>
  );
};

export default Questionnaire;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;
