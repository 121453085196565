export const setQuestions = (data) => ({
  type: "SET_QUESTIONS",
  payload: data,
});

export const inCrementPosition = () => ({
  type: "INCREMENT_POSITION",
});

export const addResponse = (data) => ({
  type: "ADD_RESPONSE",
  payload: data,
});

export const setLoading = (value) => ({
  type: "SET_LOADING",
  payload: value,
});

export const setViewResults = (data) => ({
  type: "SET_VIEW_RESULTS",
  payload: data,
});
