import React from "react";

import { percentYesNo, calculateYourCohort } from "./business.ts";

import "./styles.css";

const ResponsesList = ({ results, responses }) => {
  let mayMin = calculateYourCohort(results, responses);
  return (
    <>
      <div>
        <p style={{ marginBottom: 10 }}>
          According to these outcomes, among participants of this questionnaire
          so far, you are currently in the {mayMin.toUpperCase()}.
        </p>
        <p className="color-fr">
        Deinen Ergebnissen zufolge gehörst Du unter den bisherigen Teilnehmern beim Beantworten dieser Umfrage derzeit zur{" "}
          {mayMin === "majority" ? "MEHRHEIT." : "MINDERHEIT."}
        </p>
        <hr className="hr" />
        <p style={{ marginBottom: 10 }}>
          Here is how your answers compare with other people's:
        </p>
        <p className="color-fr">
        Hier ist ein Vergleich Deiner Antworten mit denen der anderen Teilnehmer:
        </p>
      </div>
      <hr className="hr" />
      <div>
        {results.map((result, i) => {
          const question = result?._id
          const { percentNo, percentYes } = percentYesNo(result)

          return (
            <div key={i}>
              <p style={{ marginBottom: 10 }}>{question.questionEng}</p>
              <p className="label-2">{question.questionLocalised}</p>
              <br />
              <p style={{ marginBottom: 8 }}>
                Your answer | <span className="color-fr">Deine Antwort:</span>{" "}
                {responses[i].response ? (
                  <b>
                    Yes - <span className="color-fr">JA</span>
                  </b>
                ) : (
                  <b>
                    No - <span className="color-fr">NEIN</span>
                  </b>
                )}
              </p>
              <p>
                Other people's answers: |{" "}
                <span className="color-fr">
                Die Antworten der anderen Personen:
                </span>
                <br />
                Yes | <span className="color-fr">Ja</span> {percentYes}% No |{" "}
                <span className="color-fr">Nein</span> {percentNo}%
              </p>
              <hr
                style={{
                  width: "100%",
                  border: "0.5px dotted #6699cc55",
                  marginBottom: 16,
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ResponsesList;
