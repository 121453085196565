const ImgBubble = ({
  component: Component,
  delay,
  elementRef,
  src,
  onClick,
}) => {
  if (src && onClick) {
    const Image = src;
    return (
      <Component onClick={onClick} delay={delay} ref={elementRef}>
        <Image />
      </Component>
    );
  }

  return <Component delay={delay} ref={elementRef} src={src} />;
};

export default ImgBubble;
