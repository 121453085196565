import styled, { createGlobalStyle } from "styled-components";
import Fugue from "../assets/fonts/Fugue_Regular.otf";
import Roboto from "../assets/fonts/Roboto/Roboto-Regular.ttf";

export const GlobalStyled = createGlobalStyle`
  @font-face {
    font-family: 'Fugue Regular';
    src: url(${Fugue}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${Roboto}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    margin: 0;
    width: 100%;
    font-family: "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #2a43f4;
    /* background-color: #000000; */
    font-size: 13px;
    box-sizing: border-box;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .color-fr {
    color: #99cccc;
    padding-top: 15px;
  }

  .separator{
    background-color: #6699cc;
    color: #6699cc;
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90vh;
  .bobo {
    display: none;
  }
  .reverse-bobo {
    display: flex;
  }

  @media only screen and (min-width: 840px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    min-height: 100vh;
    .bobo {
      display: flex;
    }
    .reverse-bobo {
      display: none;
    }
  }
`;
