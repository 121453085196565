import { GlobalStyled } from "./styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  About,
  AdmForm,
  ContagiousSpeech,
  Home,
  LiveStream,
  Questionnaire,
  ZoomPerformance,
} from "./pages";

import { QuestionnaireProvider } from "./context";

function App() {
  return (
    <>
      <GlobalStyled />
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contagious" element={<ContagiousSpeech />} />
          <Route exact path="/about" element={<About />} />
          <Route
            exact
            path="/questionnaire"
            element={
              <QuestionnaireProvider>
                <Questionnaire />
              </QuestionnaireProvider>
            }
          />
          <Route exact path="/live-stream" element={<LiveStream />} />
          <Route exact path="/zoom-performance" element={<ZoomPerformance />} />
          <Route exact path="/adm-form-es" element={<AdmForm locale="es" />} />
          <Route exact path="/adm-form-fr" element={<AdmForm locale="fr" />} />
          <Route exact path="/adm-form-nl" element={<AdmForm locale="nl" />} />
          <Route exact path="/adm-form-de" element={<AdmForm locale="de" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
