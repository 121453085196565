import { useState, useEffect } from "react";

import { Layout, Spinner } from "../components";
import contagious1 from "../assets/contagious_speech/3-CS.svg";
import { ReactComponent as contagiousText } from "../assets/contagious_speech/4-CS.svg";

const ContagiousSpeech = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(
        "https://contagious-speech.org/wp/wp-json/wp/v2/pages/10"
      );
      const data = await response.json();
      setData(data.content.rendered);
    };

    getData();
  }, []);

  if (!data) return <Spinner />;

  return (
    <Layout
      title="Contagious Speech"
      img={contagious1}
      textImg={contagiousText}
    >
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Layout>
  );
};

export default ContagiousSpeech;
