import React from "react";
import { percentYesNo, calculateYourCohort } from "./business.ts";
import "./styles.css";

const ResponsesList = ({ results, responses }) => {
  let mayMin = calculateYourCohort(results, responses);
  return (
    <>
      <div>
        <p style={{ marginBottom: 10 }}>
          According to these outcomes, among participants of this questionnaire
          so far, you are currently in the {mayMin.toUpperCase()}.
        </p>
        <p className="color-fr">
          D'après vos résultats, parmi les personnes ayant participé à ce
          questionnaire à ce jour, vous vous situez dans la{" "}
          {mayMin === "majority" ? "MAJORITÉ." : "MINORITÉ."}
        </p>
        <hr className="hr" />
        <p style={{ marginBottom: 10 }}>
          Here is how your answers compare with other people's:
        </p>
        <p className="color-fr">
          Voici comment vos réponses se comparent à celles des autres personnes
          participantes :
        </p>
      </div>
      <hr className="hr" />
      <div>
        {results.map((result, i) => {
          const question = result?._id
          const { percentNo, percentYes } = percentYesNo(result)

          return (
            <div key={i}>
              <p style={{ marginBottom: 10 }}>{question.questionEng}</p>
              <p className="label-2">{question.questionLocalised}</p>
              <br />
              <p style={{ marginBottom: 8 }}>
                Your answer | <span className="color-fr">Votre réponse:</span>{" "}
                {responses[i].response ? (
                  <b>
                    Yes - <span className="color-fr">Oui</span>
                  </b>
                ) : (
                  <b>
                    No - <span className="color-fr">Non</span>
                  </b>
                )}
              </p>
              <p>
                Other people's answers |{" "}
                <span className="color-fr">
                  Les réponses des autres personnes:
                </span>
                <br />
                Yes | <span className="color-fr">Oui</span> {percentYes}% No |{" "}
                <span className="color-fr">Non</span> {percentNo}%
              </p>
              <hr
                style={{
                  width: "100%",
                  border: "0.5px dotted #6699cc55",
                  marginBottom: 16,
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ResponsesList;
