import React from "react";

import { Button } from "../button/Button";

const IntroQuestions = ({ onClick }) => {
  return (
    <div>
      <h2 className="color-fr">Enquête</h2>
      <p>
        When you click “Start”, you will see 35 statements. Click “yes” if you
        agree with the statement and click “no” if you don't. When you click
        “send” on the last screen, you will see how your answers compare with
        other people's. Your answers will be saved and compared with the answers
        of previous and future users.
      </p>
      <p className="color-fr">
      Als je op ‘start’ klikt, krijg je 35 stellingen te zien. Klik op ‘ja’ als je het eens bent met de stelling en
klik op ‘nee’ als je het er niet mee eens bent. Als je in het laatste scherm op ‘verzenden’ klikt, zie je
hoe jouw antwoorden zich verhouden tot die van anderen. Je antwoorden worden opgeslagen en
vergeleken met die van eerdere en toekomstige gebruikers.
      </p>
      <Button onClick={onClick}>Start | Start</Button>
      <p>
      This questionnaire was first developed as a bilingual French/English work for the exhibition
Contagious Speech at the Gallerie UQO in Quebec, Canada. It was translated to Spanish, and adapted
for the context of the exhibition The Future we speak at the Clemente Center in New York City,
curated by Eugenia Delfini.
Your participation is strictly meant to serve your own curiosity and enjoyment. Your name will not be
asked, and your metadata will not be saved.
      </p>
      <p className="color-fr">
      Deze enquête  werd voor het eerst ontwikkeld als en tweetalig Frans/Engels werk voor de tentoonstelling Contagious Speech in Galerie UQO in Gatineau, Canada. 
      Het werd vertaald naar het Spaans en aangepast voor de context van de tentoonstelling The Future We Speak in het Clemente Center in New York. 
      Deze Nederlands/Engelse versie is gemaakt voor het Ultrasympathetic Ultratranslation Symposium van BAK in Utrecht. 
      Je deelname is uitsluitend voor jouw eigen nieuwsgierigheid en plezier bedoeld. Je naam wordt niet gevraagd en je metadata worden niet opgeslagen.
      </p>
    </div>
  );
};

export default IntroQuestions;
