import styled from "styled-components";

export const Button = styled.button`
  color: #e5e6e7;
  border: 1px solid #e5e6e7;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #559ad0;
  margin: 20px 20px 20px 0;
  font-weight: bold;
  :hover {
    cursor: pointer;
    color: #559ad0;
    border: 1px solid #559ad0;
    background-color: #e5e6e7;
  }
`;
