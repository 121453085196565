import styled from "styled-components";
import { Button } from "../button/Button";

export const Container = styled.div`
  color: white;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-height: 60px;
  padding: 8px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f1f1f188;
`;

export const TextArea = styled.textarea`
  width: 90%;
  height: 45px;
  border-radius: 4px;
  padding: 8px;
`;

export const Label = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 4px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const Btn = styled(Button)`
  background-color: ${({ bgColor }) => bgColor};
  font-size: 18px;
  border-radius: 4px;
  margin: 26px 0 20px 20px;
`;

export const Input = styled.input`
  padding: 8px;
  font-size: 16px;
`;
