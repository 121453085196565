import {
  setQuestions,
  addResponse,
  inCrementPosition,
  setLoading,
  setViewResults,
} from "../context/actions";

import { useQuestionnaireContext } from "../context";

import { HOST } from "../utils";

const useQuestionnaire = () => {
  const { state, dispatch } = useQuestionnaireContext();

  const getQuestions = async () => {
    let res = await fetch(HOST + "/questions");
    res = await res.json();
    dispatch(setQuestions(res));
  };

  const increment = () => {
    dispatch(inCrementPosition());
  };

  const addResponses = (response) => {
    dispatch(addResponse(response));
  };

  const sendResponses = async (responses) => {
    try {
      dispatch(setLoading(true));
      let res = await fetch(HOST + "/responses", {
        method: "POST",
        headers: {
          "Content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ responses }),
      });
      res = await res.json();
      if (res.status === "ok") {
        let newRes = await fetch(HOST + "/responses/count");
        newRes = await newRes.json();
        dispatch(setViewResults(newRes));
      }
      dispatch(setLoading(false));
    } catch (error) {
      throw error;
    }
  };

  const begin = () => {
    dispatch(setViewResults(null));
  };

  return {
    ...state,
    begin,
    getQuestions,
    increment,
    addResponses,
    sendResponses,
  };
};

export default useQuestionnaire;
