const initialState = {
  questions: [],
  responses: [],
  position: 0,
  loading: true,
  viewResults: "intro",
};

const questionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QUESTIONS": {
      return { ...state, questions: action.payload, loading: false };
    }
    case "INCREMENT_POSITION": {
      return { ...state, position: state.position + 1 };
    }
    case "ADD_RESPONSE": {
      return { ...state, responses: [...state.responses, action.payload] };
    }
    case "SET_LOADING": {
      return { ...state, loading: action.payload };
    }
    case "SET_VIEW_RESULTS": {
      return { ...state, viewResults: action.payload };
    }
    default:
      return state;
  }
};

export { initialState, questionnaireReducer };
