import React from "react";

import { Button } from "../button/Button";

const IntroQuestions = ({ onClick }) => {
  return (
    <div>
      <h2 className="color-fr">Umfrage</h2>
      <p>
      When you click ‘start’, you will see 35 statements. Click ‘yes’ if you agree with the statement and click ‘no’ when you don’t. When you click ‘send’ at the last screen, you will see how your answers compare with other people’s. Your answers will be saved and compared with the answers of previous and future users.
      </p>
      <p className="color-fr">
      Wenn Du auf ‚Starten‘ klickst, siehst Du 35 Aussagen. Klickst Du auf ‚Ja‘, stimmst Du einer Aussage zu, und wenn Du auf ‚Nein‘ klickst, stimmst Du nicht zu. Wenn Du bei der letzten Einblendung auf ‚Senden‘ klickst, siehst Du einen Vergleich Deiner Antworten mit denen anderer Befragter. Deine Antworten werden gespeichert und msit den Antworten früherer und künftiger Nutzer verglichen.      </p>
      <Button onClick={onClick}>Start | Starten</Button>
      <p>
      This questionnaire was first developed in English and French for the exhibition Contagious Speech at the Gallerie UQO in Quebec. It was adapted to the context of an exhibition at the Clemente Center in New York City, and translated to Spanish. A version in Dutch and English was made for BAK in Utrecht. This edition in German and English was commissioned by ACC Weimar. Your participation is strictly meant to serve your own curiosity and enjoyment. Your name will not be asked, and your metadata will not be saved.
      </p>
      <p className="color-fr">
      Diese Umfrage wurde in englischer und französischer Sprache für die Ausstellung Contagious Speech in der Gallerie UQO, Quebec, entwickelt. Es erfolgte dann im Kontext einer Ausstellung im Clemente Center in New York City eine Überarbeitung samt einer Übersetzung ins Spanische. Eine niederländisch-englische Version wurden für BAK in Utrecht erstellt. Diese deutsch-englische Version nun wurden vom ACC Weimar in Auftrag gegeben. Deine Teilnahme soll ausschließlich Deiner eigenen Neugierde und Freude dienen. Dein Name wird nicht erfragt, und Deine Metadaten werden nicht gespeichert.

      </p>
    </div>
  );
};

export default IntroQuestions;
