import styled, { keyframes } from "styled-components";

const opacity = () => keyframes`
  0% {
    opacity: 1;
  }
  /* Adding a step in the middle */
  50% {
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
`;

export const WrapperImg = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(85vh / 5);
  width: 60%;
  align-self: ${({ alignSelf }) => alignSelf};
  margin-left: 5%;
  margin-right: 5%;
  .visible {
    opacity: 0.01;
  }
  .animation {
    animation: ${opacity()} 3s alternate ease-in-out;
    /* animation-delay: 8s; */
  }

  @media only screen and (min-width: 840px) {
    top: ${({ top }) => top};
    right: ${({ right }) => right};
    bottom: ${({ bottom }) => bottom};
    left: ${({ left }) => left};
    width: ${({ width }) => width || "75%"};
    height: auto;
    align-self: auto;
  }
`;

const BaseImg = styled.img`
  width: 100%;
  position: absolute;
  opacity: 1;
`;

export const TextImg = styled.div`
  width: 95%;
  height: 95%;
  position: absolute;
  z-index: 3000;
  svg {
    fill: #6699cc;
    width: 100%;
    height: 100%;
  }
  svg:hover {
    cursor: pointer;
    fill: #99cccc;
  }
`;

export const TopImg = styled(BaseImg)`
  z-index: 20;
  transition: opacity ease-in-out;
  transition-duration: 2000ms;
  transition-delay: ${({ delay }) => delay};
`;

export const MiddleImg = styled(BaseImg)`
  z-index: 0;
  transition: opacity ease-in-out;
  transition-duration: 2000ms;
  transition-delay: ${({ delay }) => delay};
`;

export const BottomImg = styled(BaseImg)`
  z-index: -10;
  transition: opacity ease-in-out;
  transition-duration: 2000ms;
  transition-delay: ${({ delay }) => delay};
`;
