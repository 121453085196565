export const percentYesNo = (result) => {
    const { totalQuestionsTrue, totalQuestionsFalse } = result;
    const total = totalQuestionsTrue + totalQuestionsFalse;

    const percentYes = ((totalQuestionsTrue * 100) / total).toFixed(2);
    const percentNo = ((totalQuestionsFalse * 100) / total).toFixed(2);

    return {percentNo, percentYes}
}

export const calculateYourCohort = (results, responses) => {
    let mayority = 0;
    let minority = 0;
  
    for (let i = 0; i < responses.length; i++) {
      const { totalQuestionsTrue, totalQuestionsFalse } = results[i];
      const total = totalQuestionsTrue + totalQuestionsFalse;
  
      const totalPercentYes = ((totalQuestionsTrue * 100) / total).toFixed(2);
      const totalPercentNo = ((totalQuestionsFalse * 100) / total).toFixed(2);
  
      // let mayority = (totalPercentNo <= totalPercentYes) ? 'yes' : 'no'
      if (totalPercentNo <= totalPercentYes) {
        if (responses[i].response) {
          mayority = mayority + 1;
        } else {
          minority = minority + 1;
        }
      } else {
        if (responses[i].response) {
          minority = minority + 1;
        } else {
          mayority = mayority + 1;
        }
      }
    }
  
    return mayority >= minority ? "majority" : "minority";
  };
  