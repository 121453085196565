import contagious1 from "../assets/contagious_speech/1-CS.svg";
import contagious2 from "../assets/contagious_speech/2-CS.svg";
import contagious3 from "../assets/contagious_speech/3-CS.svg";
import { ReactComponent as contagiousText } from "../assets/contagious_speech/4-CS.svg";
// import constagiousTexthover from "../assets/contagious_speech/5-CS-Hover.svg";

import questionnaire1 from "../assets/questionnaire/1-QS.svg";
import questionnaire2 from "../assets/questionnaire/2-QS.svg";
import questionnaire3 from "../assets/questionnaire/3-QS.svg";
import { ReactComponent as questionnaireText } from "../assets/questionnaire/4-QS.svg";
// import questionnaireTextHover from "../assets/questionnaire/5-QS-Hover.svg";

import zoomp1 from "../assets/zoom/1-ZM.svg";
import zoomp2 from "../assets/zoom/2-ZM.svg";
import zoomp3 from "../assets/zoom/3-ZM.svg";
import { ReactComponent as zoompText } from "../assets/zoom/4-ZM.svg";
// import zoompTextHover from "../assets/zoom/5-ZM-Hover.svg";

import about1 from "../assets/about/1-AB.svg";
import about2 from "../assets/about/2-AB.svg";
import about3 from "../assets/about/3-AB.svg";
import { ReactComponent as aboutText } from "../assets/about/4-AB.svg";
// import aboutTextHover from "../assets/about/5-AB-Hover.svg";

import lives1 from "../assets/live_stream/1-LS.svg";
import lives2 from "../assets/live_stream/2-LS.svg";
import lives3 from "../assets/live_stream/3-LS.svg";
import { ReactComponent as livesText } from "../assets/live_stream/4-LS.svg";
// import livesTextHover from "../assets/live_stream/5-LS-Hover.svg";

export const contagiousImgs = [
  contagious1,
  contagious2,
  contagious3,
  contagiousText,
];
export const questionnaireImgs = [
  questionnaire1,
  questionnaire2,
  questionnaire3,
  questionnaireText,
];
export const zoomImgs = [zoomp1, zoomp2, zoomp3, zoompText];
export const aboutImgs = [about1, about2, about3, aboutText];
export const livesImgs = [lives1, lives2, lives3, livesText];

// export const HOST = "https://contagious-questionnaire.onrender.com";
export const HOST = process.env.REACT_APP_API_HOST
