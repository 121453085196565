import React, { useRef } from "react";

import { TextArea, Label, Flex, Btn, Input } from "./formElements";

const InputText = ({ item, onDelete, onModify, onCreate }) => {
  const EngRef = useRef();
  const FrRef = useRef();
  const orderRef = useRef();

  const createQuestion = () => {
    if (
      !EngRef.current.value ||
      !FrRef.current.value ||
      !orderRef.current.value
    )
      return alert("Fill in all the fields");
    let body = {
      questionEng: EngRef.current.value,
      questionLocalised: FrRef.current.value,
      position: orderRef.current.value,
    };
    onCreate(body);
  };

  const modify = () => {
    const res = window.confirm(
      `Are you sure you want to modify item ${item?.position} ?`
    );
    if (res) {
      let body = {
        _id: item?._id,
        questionEng: EngRef.current.value,
        questionLocalised: FrRef.current.value,
        position: orderRef.current.value,
      };
      onModify(body);
    }
  };

  const onRemove = () => {
    const res = window.confirm(
      `Are you sure you want to delete item ${item?.position} ?`
    );
    if (res) onDelete(item?._id);
  };

  return (
    <Flex>
      <div style={{ flex: 1 }}>
        <Label htmlFor={`textarea-eng-${item?._id}`}>Question in English</Label>
        <TextArea
          ref={EngRef}
          id={`textarea-eng-${item?._id}`}
          defaultValue={item?.questionEng}
        />
        <p>
          <br />
        </p>
        <Label htmlFor={`textarea-fr-${item?._id}`}>Question translated</Label>
        <TextArea
          ref={FrRef}
          id={`textarea-fr-${item?._id}`}
          defaultValue={item?.questionLocalised}
        />
      </div>
      <div>
        <Label hmltFor="quantity">Order:</Label>
        <Input
          ref={orderRef}
          type="number"
          id="quantity"
          name="quantity"
          min="1"
          max="35"
          defaultValue={item?.position}
        />
      </div>
      <div>
        <br />
        <Btn onClick={onCreate ? createQuestion : modify} bgColor="green">
          {onCreate ? "Add question" : "Save changes"}
        </Btn>
      </div>
      {!onCreate && (
        <div>
          <br />
          <Btn onClick={onRemove} bgColor="red">
            Delete
          </Btn>
        </div>
      )}
    </Flex>
  );
};

export default InputText;
