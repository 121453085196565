import { useState, useEffect } from "react";

import { Layout, Spinner } from "../components";
import zoomp1 from "../assets/zoom/3-ZM.svg";
import { ReactComponent as zoompText } from "../assets/zoom/4-ZM.svg";

const ZoomPerformance = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(
        "https://contagious-speech.org/wp/wp-json/wp/v2/pages/37"
      );
      const data = await response.json();
      setData(data.content.rendered);
    };

    getData();
  }, []);

  if (!data) return <Spinner />;

  return (
    <Layout title="Zoom Performance" img={zoomp1} textImg={zoompText}>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Layout>
  );
};

export default ZoomPerformance;
