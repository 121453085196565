import React from "react";

import { Button } from "../button/Button";

const IntroQuestions = ({ onClick }) => {
  return (
    <div>
      <h2 className="color-fr">Questionnaire</h2>
      <p>
        When you click “Start”, you will see 35 statements. Click “yes” if you
        agree with the statement and click “no” if you don't. When you click
        “send” on the last screen, you will see how your answers compare with
        other people's. Your answers will be saved and compared with the answers
        of previous and future users.
      </p>
      <p className="color-fr">
        Lorsque vous appuyez sur « Début », 35 affirmations s'affichent. Pour
        chaque affirmation, cliquez sur « oui » si vous êtes en accord et sur «
        non » si vous ne l'êtes pas. En sélectionnant « envoyer » au dernier
        écran, vous verrez comment vos réponses se comparent à celles des
        autres. Vos réponses seront enregistrées et comparées à celles des
        utilisateurs et des utilisatrices précédentes et futures.
      </p>
      <Button onClick={onClick}>Start | Début</Button>
      <p>
        This questionnaire was developed in the context of the exhibition
        Contagious Speech at the Galerie UQO in Quebec. Questions were extracted
        from conversations about linguistic rights in the Canadian context, as
        well as on a global scale. Your participation is only meant to serve
        your own curiosity and enjoyment. Your name will not be asked, and your
        metadata will not be saved.
      </p>
      <p className="color-fr">
        Ce questionnaire a été élaboré dans le cadre de l'exposition Contagious
        Speech à la Galerie UQO au Québec. Les questions sont tirées de
        conversations portant sur les droits linguistiques dans le contexte
        canadien, ainsi qu'à l'échelle mondiale. La participation à ce
        questionnaire est uniquement destinée à répondre à vos propres
        questionnements et conçue comme un divertissement. Votre nom ne sera pas
        demandé et vos métadonnées ne seront pas enregistrées.
      </p>
    </div>
  );
};

export default IntroQuestions;
