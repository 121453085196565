import React from "react";

import { Button } from "../button/Button";

import "./styles.css";

const QuestionForm = ({ item, onChange, refYes, refNo, handleNext }) => {
  return (
    <form style={{ width: "100%" }} onSubmit={handleNext}>
      <p className="label-1">{item.questionEng}</p>
      <p className="label-2">{item.questionLocalised}</p>
      <p>Do you agree?</p>
      <p className="color-fr">Stimmst Du zu?</p>
      <br />
      <div onChange={onChange}>
        <div>
          <input
            name="yes"
            access="false"
            id="radio-group-1667928118727-0"
            value={true}
            ref={refYes}
            type="radio"
          />
          <label htmlFor="radio-group-1667928118727-0">
            Yes | <span className="color-fr">Ja</span>
          </label>
        </div>
        <p style={{ margin: 8 }}></p>
        <div>
          <input
            name="no"
            access="false"
            id="radio-group-1667928118727-1"
            value={false}
            ref={refNo}
            type="radio"
          />
          <label htmlFor="radio-group-1667928118727-1">
            No | <span className="color-fr">Nein</span>
          </label>
        </div>
        <br />
        <Button type="submit">Next | Nächste</Button>
      </div>
    </form>
  );
};

export default QuestionForm;
