import { useState, useEffect } from "react";

import { Layout, Spinner } from "../components";
import about1 from "../assets/about/3-AB.svg";
import { ReactComponent as textAbout } from "../assets/about/4-AB.svg";

const About = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(
        "https://contagious-speech.org/wp/wp-json/wp/v2/pages/2"
      );
      const data = await response.json();
      setData(data.content.rendered);
    };

    getData();
  }, []);

  if (!data) return <Spinner />;

  return (
    <Layout title="About" img={about1} textImg={textAbout}>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Layout>
  );
};

export default About;
